import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Should We Measure Customer Satisfaction?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Satisfied customers mean profit. Customer retention and commitment results from satisfied customers that is why customer retention should be measured. When a customer is satisfied, they refer your business to other people."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Is Codestaff Customer Satisfaction Relevant To My Organization?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Every organization needs customer satisfaction because the majority of customers will return to an organization based on their level of satisfaction rather than lack of competition. The Codestaff method is the most reliable means of determining the satisfaction level of your customers."
        }
    }
}

const CustomerRetention = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Customer Retention Specialists.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Customer Retention Specialists. Top
                                    companies and start-ups choose Codestaff Customer Retention Specialists
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Customer Retention Specialist now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE CUSTOMER RETENTION SPECIALISTS' banner='/customer-retention.png' bannerAlt='customer retention banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default CustomerRetention